<template>
  <div>


    <div :class="'mobileNavBar ' + deviceType">

      <div @click="routeBack('/', false)" class="goBack" style="">
        <span  v-if="deviceType == 'ios'">
          <span style="height: 22px;position: absolute; margin-top: -1px; line-height: 22px; color: #007AFF; font-size: 17px; margin-left: 30px; font-weight: 400;">Geri</span>
          <img style="height: 22px; position: absolute;left: 12px; opacity: 1; line-height: 22px;"  :src="$root.CDN_SITE + 'icon/ios_back_laci.svg'">
        </span>
        <span  v-else>
           <img style="height: 18px; padding-left: 16px; line-height: 18px; " :src="$root.CDN_SITE + 'icon/ic_arrow_back.svg'">
        </span>
      </div>
      <div class="title" style=" color: #2d3640;">
        Bildirimlerim
      </div>
      <div class="options" >
        <router-link to="/settings/notifications/">
          <img v-if="deviceType == 'ios'" style="position:absolute;height: 18px;right: 12px;  line-height: 22px; " :src="$root.CDN_SITE + 'icon/ic_tune_laci.svg'">
          <img v-else style="height: 18px; padding-right: 16px; line-height: 18px; " :src="$root.CDN_SITE + 'icon/ic_tune.svg'">
        </router-link>
      </div>

    </div>

      <div  :style="{ 'min-height': `${windowHeight - 110}px` }">

        <div v-if="!pageLoaded" :style="{ 'min-height': `${windowHeight - 120}px` }">
          <div class="hiddendiv"></div><div class="hiddendiv"></div><div class="hiddendiv"></div>
          <loader  style="margin-top: 40vh;"></loader>
        </div>
        <div v-else style="padding-top: 60px; padding-bottom: 100px;">
          <div v-if="notifications.length" style=" margin-top: 10px; width: 100%;">

            <div @click="handleNotifClick(notification)" v-if="notifications.length" v-for="notification in notifications" class="notification-row">
              <div style="margin-right: 5px; width: 10px;">
                <div v-if="!notification.read_at" class="red-dot"></div>
              </div>
              <div v-if="notification.image_type === 'image'">
                <img class="img" :src="notification.image_url">
              </div>

              <div  v-else class="icon" :style="'background-color:'+notification.icon_background_color">
                <img :src="notification.icon" onload="SVGInject(this)" class="icon-svg">
              </div>


              <div style="width: 80%;">
                <div style="display: flex; justify-content: space-between">
                  <p class="title"> {{ notification.title }}</p>
                  <div class="since">
                    {{notification.since_short}}
                  </div>
                </div>


                <p class="text"> {{ notification.text }} <span v-if="notification.bold_text">{{notification.bold_text}}</span></p>
              </div>



            </div>

            <loader v-if="seemoredivLoader"></loader>

            <div v-if="seemorediv" @click="retrieveNotifications(limit, offset)" style="cursor: pointer; margin-top:20px;font-size: 14px; font-weight: 600;text-align: center; color: #2d3640;">
              Daha fazla gör +
            </div>

          </div>
          <div v-if="!notifications.length" style="padding-top: 50px; text-align: center;">Henüz bir bildirimin yok.</div>
        </div>
      </div>
  </div>
</template>

<script>
  import Loader from "../../../components/toolbox/loader";

  export default {
    name: "src-pages-body-feed-notifications-v1_mobile",
    components: {
      Loader
    },
    data () {
      return {
        seemorediv: false,
        pageLoaded: false,
        seemoredivLoader: false,
        notifications: [],
        offset: 0,
        limit: 10
      }
    },

    methods: {

      handleNotifClick(notification){
        this.api.user.readNotification(notification.id);
        this.$router.push(notification.target_url);
      },


      sendMessage(username, avatarURL) {
        if (!this.isLoggedIn) {
          this.$router.push('/login/');
        } else {
          this.EventBus.$emit('externalOpenChat', {r_username: username, r_avatar_url: avatarURL});
        }
      },

      retrieveNotifications(limit, offset) {
        this.seemoredivLoader = true;
        this.api.user.retrieveNotifications(limit, offset)
        .then(({data}) => {
        	let result = data;

        	if (result.success) {
            this.pageLoaded = true;
            this.seemoredivLoader = false;
            this.offset += this.limit;
            this.seemorediv = result.data.seemorediv;
            this.notifications = this.notifications.concat(result.data.notifications);
          } else {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          }
        })
        .catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        })
      }
    },

    created() {
      this.retrieveNotifications(this.limit, this.offset)
    }
  }

</script>

<style scoped lang="scss">

  .red-dot{
    background: #fd4056; width: 10px; height: 10px; border-radius: 50%;
  }

  .notification-row{
    cursor: pointer;
    margin: 0 auto;
    width: 90%;
    min-height: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 5px;



  .icon-svg /deep/ {
    width: 16px;
    height: 16px;
  path {
    fill: #fff;
  }
  }

  .img{
    border-radius: 50%;
    height: 38px; width: 38px; border:1px solid #eff3f8;
    margin-right: 10px;
  }
  .icon{
    height: 38px; width: 38px; border:1px solid #eff3f8;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  .title{
    font-size: 14px;
    font-weight: 300;
    color: #8b95a1;
  }

  .text{
    font-size: 14px;
    font-weight: 300;
    line-height: 1.38;
    color: #2d3640;
    margin-top: 2px;
  span{
    font-weight: 600;
    color: #00a575;
  }
  }

  .since{
    font-size: 11px;
    text-align: right;
    color: #8b95a1;
    align-self: flex-start;
  }
  }

  .chatrow{

    height: 56px; background-color: #ffffff;  display: flex; align-items: center;
    border-bottom: solid 1px #eaedf2; padding: 0 8px;
  }

  .chatrow.unseen{
    height: 60px; background-color: rgba(245, 245, 245,0.7);  display: flex; align-items: center;
    border-top: solid 1px #eaedf2;
  }


  .chatrow.unseen > .title{
    font-weight: 600;
    color: #2d3640;
    font-weight: 600;
  }



  .chatrow .title{
    font-size: 13px;
    font-weight: 400;
    line-height: 1.29;
    color: #8b95a1;
  }


  .messagenavlink{
    cursor: pointer;
  }
  .messagenavlink:hover{
    color: #2d3640;
  }


  .usernamefollow{
    font-size: 14px;
    line-height: 1.29;

  }

  .usernamefollow:hover{
    text-decoration: underline;

  }

</style>
